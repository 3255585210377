// Custom js below
import {addValidateEmail, validateForm, validateFormRecruitment} from './const';

$( document ).ready(function() {
    // Init wow animation
    new WOW().init();

    // Scroll fixed header
    $(window).scroll(function () {
        if ($(window).scrollTop() >= 200) {
            $('.js-scroll-header').addClass('fixed-header');
            $('.js-scroll-header').find('.logo-black').addClass('d-inline-block');
            $('.js-scroll-header').find('.logo-white').addClass('d-none');
        } else {
            $('.js-scroll-header').removeClass('fixed-header');
            $('.js-scroll-header').find('.logo-black').removeClass('d-inline-block');
            $('.js-scroll-header').find('.logo-white').removeClass('d-none');
        }
    });

    $(window).on('scroll', function() {
        // Check when scroll to footer
        if($(window).scrollTop() >= $('.dc-footer').offset().top + $('.dc-footer').innerHeight() - window.innerHeight) {
            $('.js-career-apply').addClass('remove-fixed');
            $('.dc-career-detail .dc-career__join').addClass('show');
        }
    });

    // Carousel hotjob initial
    $("#hotjob-carousel").owlCarousel({
        nav: false,
        dots: true,
        items: 1,
        smartSpeed: 300,
        animateIn: 'fadeIn',
        animateOut: 'fadeOut',
        margin: 10,
        stagePadding: 5,
    });

    // Mobile menu hide/show
    $('.js-mobile-menu-button').on('click', function () {
        $('.js-mobile-menu').toggleClass('open');
        $('body, html').toggleClass('disabled-scroll');
    });

    // Focus email input when open modal
    $('#applyJobModal').on('shown.bs.modal', function() {
        $('#inputName').focus();
        $('body, html').addClass('overflow-hidden');
    });
    $('#joinModal').on('shown.bs.modal', function() {
        $('#inputName0').focus();
        $('body, html').addClass('overflow-hidden');
    });

    // Reset form when close modal
    $('#applyJobModal').on('hidden.bs.modal', function() {
        $('.js-apply-form').trigger('reset');
        $('.js-apply-form')
            .validate()
            .resetForm();

        if ($('body, html').hasClass('overflow-hidden')) {
            $('body, html').removeClass('overflow-hidden');
        }

        $(".js-file-name").text('');
    });
    $('#joinModal').on('hidden.bs.modal', function() {
        $('.js-join-form').trigger('reset');
        $('.js-join-form')
            .validate()
            .resetForm();

        if ($('body, html').hasClass('overflow-hidden')) {
            $('body, html').removeClass('overflow-hidden');
        }

        $(".js-file-name").text('');
    });

    // Auto generate id for accordion item

    $('.dc-faq__box').each(function(i) {
        $(this).attr('id', 'faq-box' + i,);
        $(this).find('.js-accordion').attr('id', 'accordion' + i);
        const attrCollapse = {
            id: 'faq_' + i,
            'data-parent': '#accordion' + i,
        };
        $(this).find('.collapse').attr(attrCollapse);
    });

    $('.js-accordion .js-faq-item').each(function(i) {
        $(this).find('.collapse').attr('id', 'faq_' + i,);
        $(this).find('button').attr('data-target', '#faq_' + i);
    });

    // Display faq box base on select on mobile

    $('.js-select-accordion').change(function() {
        $('.dc-faq__box').hide(); // hide all with .js-accordion class
        $('#faq-box' + $(this).val()).show(); // show selected option's
    });

    //Prevent enter space character

    $("input[type='text'], input[type='email'], input[type='url']").change(function() {
        $(this).val($(this).val().trim());
    });

    // Show file name when uploaded
    $("input[type='file']").change(function(e) {
        const fileName = e.target.files[0].name;
        $(".js-file-name").text(fileName);
    });

    // Click to copy current URL
    let $temp = $("<input readonly>");
    let $url = $(location).attr('href');

    $('.js-copy-url').on('click', function(e) {
        e.preventDefault();
        $("body").append($temp);
        $temp.val($url).select();
        document.execCommand("copy");
        $temp.remove();
        alert($('.copy-text').text());
    })

    addValidateEmail();
    validateForm();
    validateFormRecruitment();
});
