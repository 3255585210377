import isEmail from "validator/lib/isEmail";
// Show validate message for hidden input
$.validator.setDefaults({ ignore: '' });

// Ignore validate accept rule

$.validator.addMethod('accept', function () { return true; });

// Validate filesize

$.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param * 1000000)
});

export const ruleValidate = {
    fullName: {
        required: true,
        maxlength: 30,
    },
    email: {
        required: true,
        validateEmail: true,
        email: true,
        maxlength: 50
    },
    phone: {
        required: true,
        number: true,
        minlength: 9,
        maxlength: 12,
    },
    position: {
        required: true,
    },
    resume: {
        required: true,
        extension: 'doc|docx|xls|xlsx|pdf',
        filesize : 30,
    },
    profileFile: {
        required: false,
        extension: 'doc|docx|xls|xlsx|pdf',
        filesize : 30,
    },
    url: {
        required: false,
        url: true,
    },
};

export const messageValidate = {
    fullName: {
        required: $('#requiredName').text(),
        maxlength: $('#maxLengthName').text(),
    },
    email: {
        required: $('#requiredEmail').text(),
        validateEmail: $('#validEmail').text(),
        email: $('#validEmail').text(),
        maxlength: $('#maxLengthEmail').text()
    },
    phone: {
        required: $('#requiredPhone').text(),
        number: $('#validPhone').text(),
        minlength: $('#maxLengthPhone').text(),
        maxlength: $('#maxLengthPhone').text()
    },
    position: {
        required: $('#requiredPosition').text()
    },
    resume: {
        required: $('#requiredCV').text(),
        extension: $('#validCV').text(),
        filesize: $('#fileSizeCV').text(),
    },
    profileFile: {
        extension: $('#validCV').text(),
        filesize: $('#fileSizeCV').text(),
    },
    url: {
        url: $('#validURL').text(),
    }
};

export function addValidateEmail() {
    $.validator.addMethod(
        'validateEmail',
        function(value) {
            const regStartWithCharacter = /^[A-Za-z0-9]/.test(value);
            if (isEmail(value) && regStartWithCharacter) {
                return true;
            }
            return false;
        },
        ''
    );
};

export function validateForm() {
    $('.js-apply-form').validate({
        // Specify validation rules
        rules: ruleValidate,
        // Specify validation error messages
        messages: messageValidate,
        errorClass: 'invalid-feedback',
        errorElement: 'div',
        highlight: function(element) {
            $(element)
                .parent()
                .addClass('is-invalid');
        },
        // Make sure the form is submitted to the destination defined
        // in the "action" attribute of the form when valid
        submitHandler: function(form) {
            form.submit();
        }
    });
};

export const ruleValidateRecruitment = {
    fullName0: {
        required: true,
        maxlength: 30,
    },
    email0: {
        required: true,
        validateEmail: true,
        email: true,
        maxlength: 50
    },
    phone0: {
        required: true,
        number: true,
        minlength: 9,
        maxlength: 12
    },
    profileFile0: {
        required: false,
        extension: 'doc|docx|xls|xlsx|pdf',
        filesize : 30,
    },
    position0: {
        required: true,
    },
    url0: {
        required: false,
        url: true,
    }
};

export const messageValidateRecruitment = {
    fullName0: {
        required: $('#requiredName0').text(),
        maxlength: $('#maxLengthName0').text(),
    },
    email0: {
        required: $('#requiredEmail0').text(),
        validateEmail: $('#validEmail0').text(),
        email: $('#validEmail0').text(),
        maxlength: $('#maxLengthEmail0').text()
    },
    phone0: {
        required: $('#requiredPhone0').text(),
        number: $('#validPhone0').text(),
        minlength: $('#maxLengthPhone0').text(),
        maxlength: $('#maxLengthPhone0').text()
    },
    position0: {
        required: $('#requiredPosition0').text(),
    },
    profileFile0: {
        required: false,
        extension: $('#validCV0').text(),
        filesize : $('#fileSizeCV0').text(),
    },
    url0: {
        url: $('#validURL0').text(),
    }
};

export function validateFormRecruitment() {
    $('.js-join-form').validate({
        rules: ruleValidateRecruitment,
        messages: messageValidateRecruitment,
        errorClass: 'invalid-feedback',
        errorElement: 'div',
        highlight: function(element) {
            $(element)
                .parent()
                .addClass('is-invalid');
        },
    });
}
